//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'FolderList',
    data() {
        return {
            list: [],
            currentFolder:'',
            loading:false
        };
    },
    created() {
        this.getList();
        window.document.title = "选择目录";
    },
    methods: {
        changeFolder(e){
            this.currentFolder = e
        },
        getList(reload = true) {
            this.loading = true;
            const that = this;
            if (reload) {
                this.pageIndex = 1;
            }
            // this.axios.post('/consumer/v1/wx/cp/order/commentList', {
            this.axios.post("/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/order/commentList", {
                pager: {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    rowsCount: 0,
                    start: 0,
                },
                queryMap: {
                    storeid: this.currentStore,
                    tel: this.mobile,
                    // "tel" :'18196632597',
                    // "status": "0"
                },
            })
            .then((res) => {
            if (res && res.data && res.data.data && res.data.data.list) {
                that.total = Math.ceil(
                res.data.data.page.rowsCount / that.pageSize
                );
                if (res.data.data.list.length > 0) {
                that.storeName = res.data.data.list[0].storename;
                that.storeId = res.data.data.list[0].storeid;
                }
                if (!reload) {
                that.list = that.list.concat(res.data.data.list);
                } else {
                that.list = res.data.data.list;
                }
            } else {
                that.list = [];
            }
            that.list.forEach((item) => {
                item.service = Number(item.service);
                item.shop = Number(item.shop);
                item.guide = Number(item.guide);
            });
            that.loading = false;
            });
        }
    },
}
